import './App.css';
import React, { useEffect, useState } from 'react';
import VideoPlayer from './VideoPlayer';
import useGetNoteDetail from './hooks/get-note-detail';
import { formatAmount, formatDate, formatView } from './utils/helperFunctions';
import useGetNoteList from './hooks/get-note-list';
import Skeleton from './components/Skeleton';
import Experience from './components/Experience';

function App() {

  const [noteId, setNoteId] = useState('');
  const [inviterId, setInviterId] = useState('');
  const [isModalOpen, setIsModalOpen] = useState(false);

  useEffect(() => {
    // Get the current URL
    const queryString = window.location.search;

    // Create a URLSearchParams object
    const urlParams = new URLSearchParams(queryString);

    // Get the query parameters
    const note_id = urlParams.get('note');
    const inviter_id = urlParams.get('inviter');

    // Update the state
    setNoteId(note_id);
    setInviterId(inviter_id);

    const timer = setTimeout(() => {
      setIsModalOpen(true)
    }, 500); // 3 seconds delay

    // Cleanup function to clear the timer if the component unmounts
    return () => clearTimeout(timer);
  }, []);

  // api 
  const { data: noteDetailData, isLoading: noteDetailIsLoading } = useGetNoteDetail({ noteId: Number(noteId), inviterId: Number(inviterId) })
  const { data: noteListData, isLoading: noteListIsLoading } = useGetNoteList({ orderBy: 'create_at', authorId: Number(noteDetailData?.data?.data?.note?.user_id) })

  const timestamp = noteDetailData?.data?.data?.note?.create_at; // Example timestamp

  // Construct the URL with the parameters
  const url = `${process.env.REACT_APP_SHARE_DEEPLINK_BASEURL}?inviter=${inviterId}&note=${noteId}`;

  // Encode the URL to ensure it's safe for use in the deep link
  const encodedUrl = encodeURIComponent(url);

  // Construct the final deep link URL
  const deepLink = `${process.env.REACT_APP_SHARE_FIREBASE_DEEPLINK_BASEURL}/?apn=com.figfig&isi=6738422335&ibi=com.starfish.figfig&link=${encodedUrl}`;

  const handleShare = async () => {
    const shareData = {
      title: 'Fig Fig',
      text: 'Check this out!.',
      url: `${process.env.REACT_APP_API}/?note=${noteId}&inviter=${inviterId}`, // Replace with your desired URL
    };

    try {
      await navigator.share(shareData);
      console.log('Share successful!');
    } catch (err) {
      console.error('Error sharing:', err);
    }
  };

  const handleOpenApp = () => {
    // Add your app opening logic here
    // console.log('Opening app...');
    window.open(deepLink, '_blank')
    setIsModalOpen(false);
  };

  if (noteDetailIsLoading || noteListIsLoading) {
    return <Skeleton />
  }


  return (
    <div className="max-w-md mx-auto min-h-screen bg-[#F2F4F9] lg:max-w-4xl">
      {/* Header */}
      <header className="flex justify-between items-center px-4 py-3 bg-[#F2F4F9]">
        <div className="flex items-center space-x-4">
          <svg className='fill-current w-4 h-4' viewBox="0 0 10 19" xmlns="http://www.w3.org/2000/svg">
            <path d="M9.67449 0.40287C10.1085 0.924524 10.1085 1.72512 9.67449 2.24677L2.97934 9.62238L9.67449 16.998C9.92837 17.5273 9.83945 18.177 9.45521 18.6003C9.07097 19.0236 8.48115 19.1216 8.00071 18.8419L0.368233 10.5996C0.138634 10.3363 0.00721185 9.98747 0 9.62238C0.00288841 9.26171 0.135282 8.91698 0.368236 8.66355L7.91702 0.40287C8.14953 0.145009 8.46583 0 8.79576 0C9.12568 0 9.44198 0.14501 9.67449 0.40287Z" fill="black" />
          </svg>
        </div>
        <button onClick={handleShare} className="p-2">
          <svg className='fill-current w-5 h-5' viewBox="0 0 23 23" xmlns="http://www.w3.org/2000/svg">
            <path fillRule="evenodd" clipRule="evenodd" d="M17.1888 23H5.81122C2.60825 23 0 20.3922 0 17.1898V5.81024C0 2.60781 2.60825 0 5.81122 0H10.7517C11.4475 0 12.0112 0.563535 12.0112 1.25921C12.0112 1.95488 11.4475 2.51842 10.7517 2.51842H5.81122C4.93274 2.51842 4.10478 2.86043 3.48285 3.48226C2.86091 4.10409 2.51884 4.9319 2.51884 5.81024V17.1859C2.51884 18.0642 2.86091 18.892 3.48285 19.5139C4.10478 20.1357 4.93274 20.4777 5.81122 20.4777H17.1888C19.0041 20.4777 20.4812 19.0008 20.4812 17.1859V11.8731C20.4812 11.4262 20.7222 11.0064 21.1109 10.781C21.3013 10.6722 21.519 10.6139 21.7406 10.6139C21.9621 10.6139 22.1798 10.6722 22.3703 10.781C22.759 11.0064 23 11.4223 23 11.8731V17.1859C23 20.3922 20.3956 23 17.1888 23ZM21.1381 7.99442C21.3208 8.09936 21.5307 8.15377 21.7406 8.15377C21.9505 8.15377 22.1604 8.09547 22.347 7.99054C22.7202 7.77678 22.9495 7.37648 22.9495 6.94897V1.25921C22.9495 0.594627 22.4092 0.0544101 21.7445 0.0544101H16.0538C15.3891 0.0544101 14.8488 0.594627 14.8488 1.25921C14.8488 1.92379 15.3891 2.46401 16.0538 2.46401H18.8331L10.6468 10.6489L10.6391 10.6566C10.1968 11.1309 10.2105 11.8704 10.6701 12.3278C10.8995 12.5571 11.1988 12.6815 11.5214 12.6815C11.8285 12.6815 12.12 12.5649 12.3416 12.3589L12.3455 12.355L20.5356 4.16629V6.95286C20.5356 7.38037 20.7688 7.78067 21.1381 7.99442Z" fill="black" />
          </svg>
        </button>
      </header>

      {/* Main Content */}
      <div className="p-4 space-y-4">
        {/* Title Section */}
        <div className="bg-white rounded-2xl p-4 space-y-3">
          <h1 className="text-lg font-medium line-clamp-2">
            {noteDetailData?.data?.data?.note?.title}
          </h1>

          <div className="flex items-center justify-between text-sm">
            <div className="flex items-center space-x-4 text-gray-500">
              <span>{formatDate(timestamp)}</span>
              <div className="flex items-center space-x-1">
                <span>👁</span>
                <span>{formatView(noteDetailData?.data?.data?.note?.num_view)}</span>
              </div>
            </div>
            <div className="text-red-500">
              Earned：<span className="font-semibold">+{formatAmount(noteDetailData?.data?.data?.note?.income)}USDT</span>
            </div>
          </div>

          {/* Video Player */}

          <VideoPlayer url={noteDetailData?.data?.data?.note?.resources[0]?.url} />
        </div>

        {/* Ad Section */}
        {/* <div className="bg-white rounded-2xl p-4">
          <div className="flex items-center text-blue-600">
            <span className="border border-blue-600 text-xs px-1 rounded mr-2">AD</span>
            购买广告位
          </div>
        </div> */}

        {/* More Videos Section */}
        <div className="bg-white rounded-2xl p-4">
          <div className="flex justify-between items-center mb-4">
            <h2 className="font-medium">更多作品</h2>
            <button className="flex items-center text-gray-600">
              More Video
              <span className="ml-1">&gt;</span>
            </button>
          </div>

          <div
            className="flex overflow-x-auto gap-3 scroll-smooth snap-x snap-mandatory hide-scrollbar"
            style={{ WebkitOverflowScrolling: 'touch' }}
          >
            {noteListData?.data?.data?.list?.map((video) => (
              <div
                key={video?.id}
                className="flex-none w-[calc(33.333%-8px)] snap-start space-y-1"
              >
                <div className="relative aspect-video bg-gray-100 rounded-lg overflow-hidden">
                  <img
                    src={video?.poster}
                    alt={video?.title}
                    className="w-full h-full object-cover"
                  />
                </div>
                <h3 className="text-xs line-clamp-2">{video?.title}</h3>
              </div>
            ))}
          </div>
        </div>
      </div>

      {/* Download Banner */}
      <div className="fixed bottom-0 left-0 right-0 bg-gradient-to-t from-black to-black/60 text-white p-4 pt-12">
        <p className="text-center mb-3">
          Download the app and earn money by uploading videos.
        </p>
        <button
          onClick={() => window.open(deepLink, '_blank')}
          className="w-full bg-[#E98B4E] text-white py-3 rounded-lg font-medium flex items-center justify-center gap-2"
        >
          Download
          <svg
            className="w-7 h-7"
            viewBox="0 0 24 24"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M12 16L7 11L8.4 9.55L11 12.15V4H13V12.15L15.6 9.55L17 11L12 16ZM6 20C5.45 20 4.97933 19.8043 4.588 19.413C4.196 19.021 4 18.55 4 18V15H6V18H18V15H20V18C20 18.55 19.8043 19.021 19.413 19.413C19.021 19.8043 18.55 20 18 20H6Z"
              fill="currentColor"
            />
          </svg>
        </button>
      </div>

      <Experience
        isOpen={isModalOpen}
        onClose={() => setIsModalOpen(false)}
        onOpenApp={handleOpenApp}
      />
    </div>
  );
}

export default App;
